import React, { useState } from "react";
import styles from "./styles.module.scss";
// utils
import {
  getMidnightToMidnightEvents,
  getNewDaytimeEvents,
  getNewNighttimeEvents,
  getEarlyHoursEvents,
} from "../../Services/Utils/EventLog";
import { getUrlParameter } from "@intelligentlilli/lilli-utils";
import { startOfDay, isAfter, addHours, format, isSameDay } from "date-fns";
// components
import EventLogSection from "../EventLogSection";
import Toggle from "../Toggle";
import Event from "../Event";

const EventLogNew = ({
  currentTab,
  events,
  noSplitting,
  noData,
  isBathroom,
  isNewVariant,
  isOverview,
  isNighttime,
}) => {
  const [showAllEvents, setShowAllEvents] = useState(true);

  // Return no data if there is no data
  if (noData) {
    return (
      <div className={styles.no_data_container}>
        <h2>Event log</h2>
        <div>No events</div>
      </div>
    );
  }

  const urlEndDateString =
    getUrlParameter("end") || format(new Date(), "yyyy-MM-dd");
  const isCurrentDay = isSameDay(new Date(urlEndDateString), new Date());
  const currentTime = new Date();

  const bathingEvents =
    !isNewVariant && events
      ? [...events].filter((event) => event.type === "hygiene")
      : [];
  const eventsToShow = !isBathroom
    ? events
    : isBathroom && showAllEvents
      ? events
      : bathingEvents;

  const morningStart = addHours(startOfDay(new Date(urlEndDateString)), 6);

  const newDaytimeEvents = getNewDaytimeEvents(
    eventsToShow,
    urlEndDateString,
    morningStart
  );

  const eventsChunkedByTimePeriod = !isNewVariant
    ? getMidnightToMidnightEvents(eventsToShow, urlEndDateString, morningStart)
    : isOverview
      ? getNewNighttimeEvents(
          getEarlyHoursEvents(eventsToShow, morningStart),
          urlEndDateString
        )?.concat(newDaytimeEvents)
      : isNighttime
        ? getNewNighttimeEvents(eventsToShow, urlEndDateString)
        : newDaytimeEvents;

  // reverse the eventsChunkedByTimePeriod array to show the latest events first
  eventsChunkedByTimePeriod?.reverse();
  // reverse all the events arrays in each section to show the latest events first
  eventsChunkedByTimePeriod?.forEach((section) => {
    section.events.reverse();
  });
  const hasEventsForTheDay =
    eventsChunkedByTimePeriod &&
    eventsChunkedByTimePeriod?.some(
      (eventsSection) => eventsSection?.events?.length > 0
    );

  // Return non-split events if requested
  if (noSplitting) {
    return (
      <div className={styles.container}>
        {eventsToShow?.length > 0 ? (
          eventsToShow.map((event, index) => (
            <Event
              key={`${currentTab}-${event.type}-${event.timePeriod}-${index}`}
              event={event.type}
              frequency={1}
              timePeriod={event.timePeriod}
            />
          ))
        ) : (
          <div>No events</div>
        )}
      </div>
    );
  }

  // Return the event log with splitting into time periods
  return (
    <div className={styles.container}>
      <h2>Event log</h2>
      {isBathroom && (
        <div className={styles.toggle_events}>
          <div className={styles.toggle}>
            <Toggle
              containerClassName={styles.toggle}
              optionClassName={styles.toggle_option}
              optionFocusedClassName={styles.toggle_option_focused}
              clickRight={() => {
                setShowAllEvents(false);
              }}
              clickLeft={() => {
                setShowAllEvents(true);
              }}
              leftSelected={showAllEvents}
              leftText="All"
              rightText="Bathing"
            />
          </div>
        </div>
      )}
      <div className={styles.inner_container}>
        {!noSplitting ? (
          hasEventsForTheDay ? (
            eventsChunkedByTimePeriod.map((eventsSection, index) => {
              if (
                !isCurrentDay ||
                (isCurrentDay && isAfter(currentTime, eventsSection.start))
              ) {
                return (
                  <EventLogSection
                    key={`${currentTab}-${index}`}
                    currentTab={currentTab}
                    events={eventsSection.events}
                    end={eventsSection.end}
                    sectionTitleFull={eventsSection.sectionTitleFull}
                    sectionTitlePartial={eventsSection.sectionTitlePartial}
                    isCurrentDay={isCurrentDay}
                    currentTime={currentTime}
                  />
                );
              } else {
                return <></>;
              }
            })
          ) : (
            <div>No events</div>
          )
        ) : events?.length > 0 ? (
          events.map((event, index) => (
            <Event
              key={`${currentTab}-${event.type}-${event.timePeriod}-${index}`}
              event={event.type}
              frequency={1}
              timePeriod={event.timePeriod}
            />
          ))
        ) : (
          <div>No events</div>
        )}
      </div>
    </div>
  );
};

export default EventLogNew;
